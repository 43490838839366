import { translations } from '~/translations'
import { StaticPlans } from '~/types/stripe/plans'

const plans: StaticPlans = {
  individual: [
    {
      id: 'translation',
      productId: 'prod_Pqq0x5Y8ohh1Qi',
      brand: translations().subscriptions.plans.brand,
      title: translations().subscriptions.plans.linguist
    },
    {
      id: 'typesetting',
      productId: 'prod_Pqq4d8pkwPDqBq',
      brand: translations().subscriptions.plans.brand,
      title: translations().subscriptions.plans.graphist
    },
    {
      id: 'duo',
      productId: 'prod_Pqq5uLJqSTh3Ar',
      brand: translations().subscriptions.plans.brand,
      title: 'DUO',
      subtitle: `${translations().subscriptions.plans.linguist} + ${translations().subscriptions.plans.graphist}`
    }
  ],
  pro: [
    {
      id: 'unique',
      productId: 'prod_PrfTL22e6p7EDO',
      brand: translations().subscriptions.plans.brandPro,
      title: translations().subscriptions.plans.single,
      subtitle: `${translations().subscriptions.plans.linguist} ${translations().subscriptions.plans.or} ${translations().subscriptions.plans.graphist}`
    },
    {
      id: 'duo',
      productId: 'prod_PrfWkolnqPsX2R',
      brand: translations().subscriptions.plans.brandPro,
      title: translations().subscriptions.plans.duo,
      subtitle: `${translations().subscriptions.plans.linguist} + ${translations().subscriptions.plans.graphist}`
    }
  ]
}

const getPlanFromId = (productId: string) => {
  const allPlans = [...plans.individual, ...plans.pro]
  console.log({
    productId,
    allPlans
  })
  return allPlans.find(plan => plan.productId === productId)
}

const uniqueLicenseRoles = [
  {
    label: translations().subscriptions.plans.linguist,
    value: 'translation'
  },
  {
    label: translations().subscriptions.plans.graphist,
    value: 'typesetting'
  },
]

const indivDuoId = 'prod_Pqq5uLJqSTh3Ar'
const proUniqueId = 'prod_PrfTL22e6p7EDO'

export {
  plans,
  uniqueLicenseRoles,
  getPlanFromId,
  indivDuoId,
  proUniqueId
}